<template>
    <div>
        <ul class="footer flex jc-sa w100pc fixed bott0 bg-fff aic footer">
            <li class="item">
                <router-link to="/index" class="flex2 aic f20 yellow" active-class="active">
                    <i class="icon-home iconfont f18 text"></i>
                    <p class="mt-5 f12 text">首页</p>
                </router-link>
            </li>
            <li class="item" v-if="reportsCompany || this.user.manageDeptId != 0 || reportsDept">
                <router-link to="/count" class="flex2 aic f20 text" active-class="active">
                    <van-icon class="text" name="bar-chart-o" />
                    <p class="mt-5 text f12">填报统计</p>
                </router-link>
            </li>
            <li class="item" v-if="cost">
                <router-link to="/cost" class="flex2 aic f20 text" active-class="active">
                    <van-icon class="text" name="after-sale" />
                    <p class="mt-5 text f12">成本统计</p>
                </router-link>
            </li>
            <li class="item">
                <router-link to="/my" class="flex2 aic f20 text" active-class="active">
                    <van-icon class="text" name="user-o"></van-icon>
                    <p class="mt-5 text f12">我的</p>
                </router-link>
            </li>
    </ul>
  </div>
</template>

<script>
    export default {
        data() {
            return {
                user: JSON.parse(localStorage.userInfo),
                reportsCompany: false,
                reportsDept: false,
                cost: false
            }
        },
        mounted() {
            this.dealWith()
        },
        methods: {
            dealWith() {
                this.user = JSON.parse(localStorage.userInfo)
                this.reportsCompany = false
                this.reportsDept = false
                this.cost = false
                for(let i in this.user.functionList){
                    if(this.user.functionList[i].name == '查看全公司工时'){
                        this.reportsCompany = true
                    }
                    if(this.user.functionList[i].name == '查看本部门工时'){
                        this.reportsDept = true
                    }
                }
                for(let i in this.user.moduleList){
                    if(this.user.moduleList[i].name == '工时成本统计'){
                        this.cost = true
                    }
                }
            }
        }
    };
</script>

<style scoped lang="less">
    .footer {
        border-top: 1px solid #ebebeb;
        height: 50px;
        position: fixed;
        bottom: 0;
        width: 100%;
        left: 0;
        z-index: 10;
        .text {
            color: #797d82;
        }
        .active .text {
            color: #20a0ff;
        }
    }
</style>
