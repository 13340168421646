<template>
<div>
    <div class="title222">
        <van-sticky offset-top="-0.9rem">
            <van-cell :title="'选择日期：' + costDateTitle" @click="costDateShow = true" class="costDateSel" />
            <van-calendar v-model="costDateShow" type="range" :allow-same-day="true" :show-confirm="false" @confirm="dateConfirm" :min-date="minDate" :max-date="maxDate" />
            <van-tabs v-model="active" @change="activeChange" background="#20a0ff" title-active-color="#fff" title-inactive-color="#3c3c45" style="height:1.17333rem;">
                <van-tab title="项目"></van-tab>
                <van-tab title="项目分类" v-if="user.companyId != '1071'"></van-tab>
            </van-tabs>
        </van-sticky>
    </div>
    <div style="padding-top:2px;margin-bottom:1.33333rem">
            <van-cell v-if="listLoading" style="padding-top:2px;background-color: transparent;">
                <template #default>
                    <van-loading color="#1989fa" size="24" text-size="12" style="text-align:center;">加载中...</van-loading>
                </template>
            </van-cell>
            <van-cell v-if="timeCostList.length == 0" style="padding-top:2px;background-color: transparent;">
                <template #default>
                    <div style="text-align:center;color:#7d7e80;font-size:13px">暂无数据</div>
                </template>
            </van-cell>
            <template v-else>
                <span v-if="active == 0">
                <van-cell v-for="item in timeCostList" :key="item.id" style="padding: 2px 4px;background-color: transparent;">
                    <div style="padding: 0.26667rem 0.42667rem;background-color: #fff;">
                        <van-row>
                            <van-col span="6" style="text-align:center;color:#969799">项目名称</van-col>
                            <van-col span="18">{{item.project}}</van-col>
                        </van-row>
                        <van-row>
                            <van-col span="6" style="text-align:center;color:#969799">{{ user.companyId == '7030' ? '项目令号' : '项目编号' }}</van-col>
                            <van-col span="18">{{item.projectCode}}</van-col>
                        </van-row>
                        <van-row>
                            <van-col v-if="countHours" span="4" style="text-align:center;color:#969799">工时</van-col>
                            <van-col v-if="countHours" span="5" style="text-align:right">{{item.cost.toFixed(1)}} h</van-col>
                            <van-col v-if="countCost" span="5" style="text-align:right;color:#969799">成本</van-col>
                            <van-col v-if="countCost" span="8" style="text-align:right">￥ {{item.costMoney.toFixed(2)}}</van-col>
                        </van-row>
                    </div>
                </van-cell>
                </span>
                <span v-if="active == 1">
                <van-cell v-for="item in timeCostList" :key="item.category" style="padding: 2px 4px;background-color: transparent;">
                    <div style="padding: 0.26667rem 0.42667rem;background-color: #fff;">
                        <van-row>
                            <van-col span="6" style="text-align:center;color:#969799">分类名称</van-col>
                            <van-col span="18">{{item.categoryName}}</van-col>
                        </van-row>
                        <van-row>
                            <van-col v-if="countHours" span="4" style="text-align:center;color:#969799">工时</van-col>
                            <van-col v-if="countHours" span="5" style="text-align:right">{{item.cost.toFixed(1)}} h</van-col>
                            <van-col v-if="countCost" span="5" style="text-align:right;color:#969799">成本</van-col>
                            <van-col v-if="countCost" span="8" style="text-align:right">￥ {{item.costMoney.toFixed(2)}}</van-col>
                        </van-row>
                    </div>
                </van-cell>
                </span>
            </template>
    </div>
    
    <Footer page="cost"></Footer>
</div>
</template>

<script>
import Footer from "@/components/Footer";
export default {
    components: {
        Footer
    },
    data() {
        return {
            countCost: false,
            countHours: false,
            active: 0,
            listLoading: false,
            minDate: new Date(2020,0,1),
            maxDate: new Date(2025,11,31),
            costDateTitle: '',
            costDate: [],
            costDateShow: false,
            timeCostList: [],
            user: JSON.parse(localStorage.userInfo),
        }
    },
    mounted() {
        let user = JSON.parse(localStorage.userInfo)
        for(let i in user.functionList){
            if(user.functionList[i].name == '查看工时统计'){
                this.countHours = true
            }
            if(user.functionList[i].name == '查看成本统计'){
                this.countCost = true
            }
        }
        let end = new Date()
        let start = new Date(end.getFullYear(),end.getMonth(),1)
        this.costDate = [this.formatDate(start),this.formatDate(end)]
        this.costDateTitle = this.dateToTitle(this.costDate)
        this.getTimeCost()
    },
    methods: {
        formatDate(date) {
            let mon = date.getMonth() + 1
            return `${date.getFullYear()}-${mon<10?'0'+mon:mon}-${date.getDate()<10?'0'+date.getDate():date.getDate()}`;
        },
        dateToTitle(dateArr){
            let start = dateArr[0].split('-')
            let end = dateArr[1].split('-')
            return `${start[0]}年${start[1]}月${start[2]}日\u3000至\u3000${end[0]}年${end[1]}月${end[2]}日`
        },
        activeChange(name,title){
            this.timeCostList = []
            this.getTimeCost()
        },
        dateConfirm(value,date){
            this.costDate = [this.formatDate(value[0]),this.formatDate(value[1])]
            this.costDateTitle = this.dateToTitle(this.costDate)
            this.costDateShow = false
            this.getTimeCost()
        },
        

        getTimeCost(){
            let url = this.active ? '/project/getTimeCostByCategory' : '/project/getTimeCost'
            this.listLoading = true
            this.$axios.post(url, {
                startDate: this.costDate[0],
                endDate: this.costDate[1],
                type: 0
            }).then(res => {
                if(res.code == "ok") {
                    this.listLoading = false
                    this.timeCostList = res.data.costList
                } else {
                    this.listLoading = false
                    this.$toast.clear();
                    this.$toast.fail('获取失败');
                }
            }).catch(err=> {this.listLoading = false;this.$toast.clear();console.log(err)});
        }
    },
}
</script>

<style>
.title222 {
    background-color: #20a0ff;
    overflow: hidden;
}
.costDateSel {
    background-color: #20a0ff;
    color: #fff;
    font-size: 12px !important;
    padding-top: 0.14rem;
    padding-left: 0.43rem;
    padding-bottom: 0.3rem;
    height: 1.08rem;
}
.costDateSel::after{
    display: none;
}
.title222 .van-tabs__line{
    background-color: #f7f7f7;
}
</style>